/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import Button from '../components/button';
import ShapedImage from '../components/shaped-image';

const LinksPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            noSignUpForm
            hideHeader
            hideLinks
        >
            <SEO
                title="Tom Hirst's Links"
                description="Links to Tom Hirst's resources, products, articles, social media and newsletter."
            />
            <div className="links-page">
                <Section narrow>
                    <ShapedImage
                        name="tom-hirst-headshot.png"
                        alt="Tom Hirst on-site with a client in Oslo"
                        pullRight
                        small
                    />
                    <h1>Tom Hirst</h1>
                    <hr />
                    <h2>Story</h2>
                    <ul style={ { listStyle: 'none', marginLeft: 0 }}>
                        <li>
                            <Button to="/about" text="Who I am, what I've done and where I'm going" icon="arrow-right" color="green" />
                        </li>
                        <li>
                            <Button to="/2020-a-year-in-review" text="£100K+ from freelancing in 2020" icon="arrow-right" color="green" />
                        </li>
                        <li>
                            <Button to="/2021-a-year-in-review" text="$40K+ digital product sales in 2021" icon="arrow-right" color="green" />
                        </li>
                        <li>
                            <Button to="/2022-a-year-in-review" text="8x consulting revenue in 2022" icon="arrow-right" color="green" />
                        </li>
                    </ul>
                    <h2>Free Resources</h2>
                    <ul style={ { listStyle: 'none', marginLeft: 0 }}>
                        <li>
                            <Button to="https://tomhir.st/10-steps" external text="10 Steps To Becoming A Better Freelancer" icon="book" color="green" />
                        </li>
                        <li>
                            <Button to="/join" text="Newsletter" icon="envelope" color="green" />
                        </li>
                        <li>
                            <Button to="/articles" text="Articles" icon="newspaper" color="green" />
                        </li>
                    </ul>
                    <h2>Products</h2>
                    <ul style={ { listStyle: 'none', marginLeft: 0 }}>
                        <li>
                            <Button to="https://tomhir.st/pfp" external text="Pricing Freelance Projects" icon="book" color="green" />
                        </li>
                        <li>
                            <Button to="https://tomhir.st/pwp" external text="The Personal Website Playbook" icon="external-link-alt" color="green" />
                        </li>
                        <li>
                            <Button to="https://tomhir.st/wtd" external text="Writing Tweets Daily" icon="external-link-alt" color="green" />
                        </li>
                        <li>
                            <Button to="https://tomhir.st/tib" external text="The Independence Bundle" icon="parachute-box" color="green" />
                        </li>
                    </ul>
                    <h2>Ask</h2>
                    <ul style={ { listStyle: 'none', marginLeft: 0 }}>
                        <li>
                            <Button to="mailto:hello@tomhirst.com" external text="Email" icon="envelope" color="green" />
                        </li>
                    </ul>
                    <h2>Find</h2>
                    <ul style={ { listStyle: 'none', marginLeft: 0 }}>
                        <li>
                            <Button to="https://twitter.com/tom_hirst" external text="Twitter" icon={['fab', 'twitter']} color="green" />
                        </li>
                        <li>
                            <Button to="https://gumroad.com/tom_hirst" external text="Gumroad" icon="external-link-alt" color="green" />
                        </li>
                        <li>
                            <Button to="https://www.instagram.com/tom_hirst" external text="Instagram" icon={['fab', 'instagram']} color="green" />
                        </li>
                        <li>
                            <Button to="https://www.linkedin.com/in/tomhirst" external text="LinkedIn" icon={['fab', 'linkedin']} color="green" />
                        </li>
                        <li>
                            <Button to="https://github.com/tomhirst" external text="GitHub" icon={['fab', 'github']} color="green" />
                        </li>
                    </ul>
                </Section>
            </div>
        </Layout>
    );
};

LinksPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

LinksPage.defaultProps = {
    location: {},
};

export default LinksPage;
